<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header :add_class="['header-auto-show']" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <!-- Page Title-->
    <div v-if="isFilter" class="page-title page-title-fixed">
      <a @click="reloadPage" class="page-title-icon rounded-s ms-3 bg-theme color-theme"><i
          class="fas fa-chevron-left"></i></a>
      <h1 class="font-18 mx-auto">Lowongan</h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div v-if="isFilter" class="page-title-clear"></div>

    <!-- End Page Title-->
    <div class="page-content">
      <!-- Banner -->
      <div v-if="!isFilter" class="card bg-banner" data-card-height="250">
        <div class="card-top text-center">
          <div class="content">
            <div class="search-box search-dark shadow-xl bg-theme mt-1">
              <i class="fa fa-search"></i>
              <input type="text" class="border-0" placeholder="Cari Lowongan" v-model="dataSearch"
                @keyup.enter="searchFilter" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="isFilter" class="" data-card-height="">
        <div class="text-center">
          <div class="content">
            <div class="search-box search-dark shadow-xl bg-theme mt-1">
              <i class="fa fa-search"></i>
              <input type="text" class="border-0" placeholder="Cari Lowongan" v-model="dataSearch"
                @keyup.enter="searchFilter" />
            </div>
          </div>
        </div>
      </div>
      <!-- End Banner -->
      <div :class="{ 'mt-3': isFilter }">
        <FilterButton @openFilter="openFilter" :dataFilterList="dataFilterList" @resetFilter="togleReset" />
      </div>

      <div v-if="listJob.length === 0" class="mb-5">
        <EmptyState :message1="'Maaf saat ini belum ada lowongan yang tersedia'" :cardCenter="false" />
      </div>

      <!--Job Listing -->
      <ListJob v-for="job in listJob" :key="job.slug" :job="job" :inviteCode="inviteCode" />

      <!--End Job Listing -->
    </div>

    <FilterList @filterList="addFilterData" @close="menuClose" :resetFilter="resetFilter" />

    <!--User login modals -->
    <div id="need-login" class="menu menu-box-modal bg-highlight rounded-m" data-menu-height="310" data-menu-width="350"
      style="display: block; width: 350px; height: 310px">
      <h1 class="text-center mt-4">
        <i class="fa fa-3x fa-lightbulb color-white rounded-circle"></i>
      </h1>
      <h1 class="text-center mt-3 text-uppercase color-white font-700"></h1>
      <p class="boxed-text-l color-white opacity-70">
        Ada 100+ lowongan lagi yang bisa kamu lihat,
        <br /> ayo login sekarang untuk melihat lebih lanjut
      </p>
      <router-link :to="{ name: 'SignIn' }" class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        ">Daftar Sekarang</router-link>
    </div>
    <!--User login modals -->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import { useRouter } from "vue-router"

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";

import ListJob from "@/components/list_job/ListJob";
import FilterButton from "@/components/job/FilterButton";
import FilterList from "@/components/job/FilterList";

export default {
  name: "Vacancy",
  components: {
    Header,
    Footer,
    ListJob,
    FilterButton,
    FilterList,
    EmptyState,
  },
  data() {
    return {
      router: useRouter(),
      previousUrl: '',
      dataSearch: "",
      resetFilter: false,
      dataFilterList: {},
      listJob: [],
      apiUrl: "/api/v1/job-seeker/job-list/vacancy/",
      isAuthenticated: this.$store.state.isAuthenticated,
      isLoading: true,
      next: "",
      reqData: false,
      isFilter: false,
    };
  },
  beforeMount() {
    this.listJob = this.$store.state.jobList.job;
    this.next = this.$store.state.jobList.next;
    if (this.isAuthenticated) {
      this.getInviteLink();
    }
    // } else {
    //   this.proceedStoredHistory();
    // }
  },
  mounted() {
    init_template();
    this.proceedStoredHistory()
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    this.router.beforeEach((to, from) => {
      this.previousUrl = from.fullPath
    })
    this.getMoreJobs();
  },

  beforeRouteLeave(to, from, next) {
    // Save the current state of list data in the Vuex store
    let jobList = {
      job: this.listJob,
      next: this.next
    }
    this.$store.commit('setListData', jobList);
    next();
  },
  methods: {
    menuClose,
    menuOpen,
    async proceedStoredHistory() {
      if (this.$route.query.f !== undefined) {
        this.dataSearch = this.$route.query.f
        this.isFilter = true
        this.filter()
      } else {
        if (this.$store.state.jobList.job.length === 0) {
          await this.getJobList();
        }
      }
    },
    async getInviteLink() {
      await axios
        .get("/api/v1/auth/invite-code")
        .then((response) => {
          this.inviteCode = response.data.invite_code;
          this.getJobList();
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },

    togleReset() {
      this.resetFilter = true;
    },
    openFilter(data) {
      this.menuOpen(data);
      this.resetFilter = false;
    },
    getMoreJobs() {
      window.onscroll = () => {
        var bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 2;
        if (bottomOfWindow && this.next !== null) {
          this.getJobList();
        }
        if (bottomOfWindow && !this.isAuthenticated) {
          this.menuOpen("need-login");
        }
      };
    },
    /*listing job*/
    async getJobList(urlP) {
      var url = urlP ? urlP : this.apiUrl;
      this.next ? (url = this.next) : "";
      if (!this.reqData) {
        this.reqData = true;
        await axios
          .get(url)
          .then((response) => {
            this.next = response.data.next;
            response.data.results.forEach((item) => {
              if (!this.listJob.includes(item)) {
                this.listJob.push(item);
              }
            });
            this.isLoading = false;
            this.reqData = false;

          })
          .catch((error) => {
            this.reqData = false;
            console.log(error);
          });
      }
    },

    /*filter job*/
    reloadPage() {
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    },
    addFilterData(data) {
      this.dataFilterList = data;
      this.filter("", this.dataFilterList);
    },
    searchFilter() {
      this.$router.push({ query: { f: this.dataSearch } })
      setTimeout(() => {
        this.isLoading = true;
        this.filter();
      }, 1000);
    },
    filter(event, data) {
      this.isLoading = false;
      this.listJob = [];
      var urlFilter = this.apiUrl + "?";
      /*search*/
      this.dataSearch ? (urlFilter += "&search=" + this.dataSearch) : "";
      if (this.dataSearch.length) {
        this.isFilter = true;
      }

      /*filter*/
      if (data) {
        if (Object.entries(data).length) {
          this.isFilter = true;
        }
        for (const key in data) {
          let params = [];
          data[key].forEach((item) => {
            params.push(item.value);
          });
          urlFilter += "&" + key + "=" + params.join("|");
        }
      }
      this.next = "";
      this.getJobList(urlFilter);
    },
  },
};
</script>

<style scoped>
.bg-banner {
  background-image: url("~@/assets/images/banner/vacancy.svg");
}
</style>
